import React, {useRef, useState} from "react"
import { StaticImage } from 'gatsby-plugin-image';
import NavBar from '../components/navbar';
import Footer from '../components/footer';
import HeadData from '../data/HeadData';

const CoursesPage = (props) => {
    return (
        <div>
            <HeadData title='Disc Golf Courses' />
            <NavBar key="navbar" path={props.path}/>
            <div className="flex flex-row flex-wrap lg:flex-col bg-white items-center justify-center pt-16 lg:pt-32 pb-16">
                <div className="w-full max-w-4xl px-10 mt-5 mb-10">
                    <div className="relative animate-drop2 transform scale-0 border-b-4 border-site-blue">
                        <h1 className="text-2xl md:text-4xl font-medium text-center font-sans mb-3">
                            Disc Golf Courses in Thailand
                        </h1>
                    </div>
                </div>
                <div className="w-full max-w-2xl px-10 mb-5">
                  <div className="flex flex-col items-center justify-center border-b border-site-blue pb-3 mb-8">
                    <h2 className="font-sans mt-2 mb-3 text-xl font-medium text-center">Laem Sor Beach Disc Golf & Acoustic Cafe | <span className="font-bold">9 holes</span></h2>
                    <p className="font-sans text-sm mb-2 italic text-center">Koh Samui</p>
                    <p className="font-sans mb-3 text-center">Located just 10 minutes south of Lamai, Laem Sor Beach Disc Golf and Acoustic Cafe is Koh Samui’s premiere 18 hole disc golf course and offers a relaxed atmosphere for people looking to break away from the crowds and explore the more natural side of Koh Samui.</p>
                    <a href="https://www.discgolfscene.com/courses/Laem_Sor_Beach_Disc_Golf_and_Acoustic_Cafe" target="_blank" rel="noopener noreferrer">
                      <button className="w-auto py-2 px-4 bg-site-blue hover:bg-white text-white hover:text-site-blue border border-site-blue text-lg uppercase font-medium">
                        Course Details
                      </button>
                    </a>
                  </div>

                  <div className="flex flex-col items-center justify-center border-b border-site-blue pb-3 mb-8">
                    <h2 className="font-sans mt-2 mb-3 text-xl font-medium text-center">Ace Park Phangan | <span className="font-bold">9 holes</span></h2>
                    <p className="font-sans text-sm mb-2 italic text-center">Koh Phangan</p>
                    <p className="font-sans mb-3 text-center">Ace Park Phangan is first Disc golf course at Koh Phangan and it is designed for all level players. All holes should be Aceable, definedly birdable even for the beginner level players but still have some challenge for advanced players.</p>
                    <a href="https://www.discgolfscene.com/courses/ace-park-phangan-11690" target="_blank" rel="noopener noreferrer">
                      <button className="w-auto py-2 px-4 bg-site-blue hover:bg-white text-white hover:text-site-blue border border-site-blue text-lg uppercase font-medium">
                        Course Details
                      </button>
                    </a>
                  </div>

                  <div className="flex flex-col items-center justify-center border-b border-site-blue pb-3 mb-8">
                    <h2 className="font-sans mt-2 mb-3 text-xl font-medium text-center">Bophut Hills | <span className="font-bold">9 holes</span></h2>
                    <p className="font-sans text-sm mb-2 italic text-center">Bophut, Koh Samui</p>
                    <a href="https://www.discgolfscene.com/courses/Bophut_Hills" target="_blank" rel="noopener noreferrer">
                      <button className="w-auto py-2 px-4 bg-site-blue hover:bg-white text-white hover:text-site-blue border border-site-blue text-lg uppercase font-medium">
                        Course Details
                      </button>
                    </a>
                  </div>

                  <div className="flex flex-col items-center justify-center border-b border-site-blue pb-3 mb-8">
                    <h2 className="font-sans mt-2 mb-3 text-xl font-medium text-center">Disc Golf Thailand | <span className="font-bold">18 holes</span></h2>
                    <p className="font-sans text-sm mb-2 italic text-center">Chang Wat</p>
                    <a href="https://www.discgolfscene.com/courses/Disc_Golf_Thailand" target="_blank" rel="noopener noreferrer">
                      <button className="w-auto py-2 px-4 bg-site-blue hover:bg-white text-white hover:text-site-blue border border-site-blue text-lg uppercase font-medium">
                        Course Details
                      </button>
                    </a>
                  </div>

                  <div className="flex flex-col items-center justify-center border-b border-site-blue pb-3 mb-8">
                    <h2 className="font-sans mt-2 mb-3 text-xl font-medium text-center">Gymkhana Sports Club | <span className="font-bold">18 holes</span></h2>
                    <p className="font-sans text-sm mb-2 italic text-center">Chiang Mai</p>
                    <p className="font-sans mb-3 text-center">Gymkhana Country Club in the center of Chiang Mai, Thailand, has for 125 years claimed one of the most beautiful ball golf courses in this part of the world.</p>
                    <a href="https://www.discgolfscene.com/courses/Gymkhana_Sports_Club" target="_blank" rel="noopener noreferrer">
                      <button className="w-auto py-2 px-4 bg-site-blue hover:bg-white text-white hover:text-site-blue border border-site-blue text-lg uppercase font-medium">
                        Course Details
                      </button>
                    </a>
                  </div>

                  <div className="flex flex-col items-center justify-center border-b border-site-blue pb-3 mb-8">
                    <h2 className="font-sans mt-2 mb-3 text-xl font-medium text-center">Koh Mak DGC | <span className="font-bold">9 holes</span></h2>
                    <p className="font-sans text-sm mb-2 italic text-center">Koh Mak</p>
                    <a href="https://www.discgolfscene.com/courses/Koh_Mak_DGC" target="_blank" rel="noopener noreferrer">
                      <button className="w-auto py-2 px-4 bg-site-blue hover:bg-white text-white hover:text-site-blue border border-site-blue text-lg uppercase font-medium">
                        Course Details
                      </button>
                    </a>
                  </div>

                  <div className="flex flex-col items-center justify-center border-b border-site-blue pb-3 mb-8">
                    <h2 className="font-sans mt-2 mb-3 text-xl font-medium text-center">Koh Som | <span className="font-bold">9 holes</span></h2>
                    <p className="font-sans text-sm mb-2 italic text-center">Koh Samui</p>
                    <a href="https://www.discgolfscene.com/courses/Koh_Som" target="_blank" rel="noopener noreferrer">
                      <button className="w-auto py-2 px-4 bg-site-blue hover:bg-white text-white hover:text-site-blue border border-site-blue text-lg uppercase font-medium">
                        Course Details
                      </button>
                    </a>
                  </div>

                  <div className="flex flex-col items-center justify-center border-b border-site-blue pb-3 mb-8">
                    <h2 className="font-sans mt-2 mb-3 text-xl font-medium text-center">Lanna Rocks DG | <span className="font-bold">18 holes</span></h2>
                    <p className="font-sans text-sm mb-2 italic text-center">Chiang Mai</p>
                    <a href="https://www.discgolfscene.com/courses/Lanna_Rocks_DG" target="_blank" rel="noopener noreferrer">
                      <button className="w-auto py-2 px-4 bg-site-blue hover:bg-white text-white hover:text-site-blue border border-site-blue text-lg uppercase font-medium">
                        Course Details
                      </button>
                    </a>
                  </div>

                  <div className="flex flex-col items-center justify-center border-b border-site-blue pb-3 mb-8">
                    <h2 className="font-sans mt-2 mb-3 text-xl font-medium text-center">Pattaya DGC | <span className="font-bold">18 holes</span></h2>
                    <p className="font-sans text-sm mb-2 italic text-center">Pattaya</p>
                    <a href="https://www.discgolfscene.com/courses/Pattaya_DGC" target="_blank" rel="noopener noreferrer">
                      <button className="w-auto py-2 px-4 bg-site-blue hover:bg-white text-white hover:text-site-blue border border-site-blue text-lg uppercase font-medium">
                        Course Details
                      </button>
                    </a>
                  </div>

                  <div className="flex flex-col items-center justify-center border-b border-site-blue pb-3 mb-8">
                    <h2 className="font-sans mt-2 mb-3 text-xl font-medium text-center">Samui Disc Golf | <span className="font-bold">11 holes</span></h2>
                    <p className="font-sans text-sm mb-2 italic text-center">Mae Nam, Koh Samui</p>
                    <p className="font-sans mb-3 text-center">The park is well maintained. 24 chain baskets with chasity belt. Turf tees are 190cm wide by 300cm long. Signage is clearly marked. Some definite short holes but challenging longer ones as well.</p>
                    <a href="https://www.discgolfscene.com/courses/Samui_Disc_Golf" target="_blank" rel="noopener noreferrer">
                      <button className="w-auto py-2 px-4 bg-site-blue hover:bg-white text-white hover:text-site-blue border border-site-blue text-lg uppercase font-medium">
                        Course Details
                      </button>
                    </a>
                  </div>

                  <div className="flex flex-col items-center justify-center border-b border-site-blue pb-3 mb-8">
                    <h2 className="font-sans mt-2 mb-3 text-xl font-medium text-center">Siam Royal By DGC | <span className="font-bold">36 holes</span></h2>
                    <p className="font-sans text-sm mb-2 italic text-center">Koh Chang</p>
                    <a href="https://www.discgolfscene.com/courses/Siam_Royal_By_DGC" target="_blank" rel="noopener noreferrer">
                      <button className="w-auto py-2 px-4 bg-site-blue hover:bg-white text-white hover:text-site-blue border border-site-blue text-lg uppercase font-medium">
                        Course Details
                      </button>
                    </a>
                  </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default CoursesPage;